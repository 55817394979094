import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdEye, TooltipBox } from 'liber-components';
import Icon from '@mdi/react';
import { mdiDownloadMultiple } from '@mdi/js';
import { Card, Row, Cell, Divider } from './AssignmentTerm.styles';
import { fetchAnticipation } from '../../actions/AnticipationCheckoutActions';
import { dispatchDataLayerEvent } from '../../../../vendor/Utils';

export const ListingCard = ({ anticipation, onFetchAnticipation, isAdmin, vendor, operator }) => {
  React.useEffect(() => {
    onFetchAnticipation();
  }, []);

  const { borderos, total } = anticipation;

  const showAssignmentTerm = () => {
    dispatchDataLayerEvent('checkoutVisualizarTermoDeCessao', isAdmin);
  };

  const downloadAllAssignmentTerms = useCallback(() => {
    dispatchDataLayerEvent('Vendor antecipation checkout: download term');
    dispatchDataLayerEvent('checkoutBaixarTodosOsTermosDeCessao', isAdmin, {
      id: vendor.id,
      name: vendor.name,
      kind: isAdmin ? 'Admin' : 'Vendor',
      operatorId: operator.id,
      operatorName: operator.name,
      representanteLegal: operator.legal_representative,
    });
  }, [isAdmin, vendor, operator]);

  return (
    <Card>
      <Row>
        <Cell strong width="48px">
          Número
        </Cell>
        <Cell strong width="110px">
          Total de Títulos
        </Cell>
        <Cell strong width="130px">
          Valor Líquido (R$)
        </Cell>
        <Cell strong width="45px">
          Ações
        </Cell>
      </Row>
      {borderos.map(({ id, invoiceCount, netValue, documentUrl }) => (
        <Row key={`antecipation-row-${id}`}>
          <Cell width="48px">{id}</Cell>
          <Cell width="110px">{invoiceCount}</Cell>
          <Cell width="130px">{netValue}</Cell>
          <TooltipBox mount="bottom" fixed content="Visualizar termo de cessão">
            <Cell center width="45px">
              <a
                onClick={showAssignmentTerm}
                href={documentUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdEye />
              </a>
            </Cell>
          </TooltipBox>
        </Row>
      ))}
      <Divider />
      <Row>
        <Cell strong width="48px">
          TOTAL
        </Cell>
        <Cell width="110px">{total.invoiceCount}</Cell>
        <Cell width="130px">{total.netValue}</Cell>
        <TooltipBox mount="bottom" fixed content="Baixar todos os termos de cessão">
          <Cell center width="45px">
            <a
              onClick={downloadAllAssignmentTerms}
              href={total.documentsUrl}
              target="_blank"
              rel="noopener noreferrer"
              download="Termos de Cessão.zip"
            >
              <Icon path={mdiDownloadMultiple} />
            </a>
          </Cell>
        </TooltipBox>
      </Row>
    </Card>
  );
};

ListingCard.propTypes = {
  anticipation: PropTypes.shape({
    borderos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        invoiceCount: PropTypes.number,
        netValue: PropTypes.string,
        documentUrl: PropTypes.string,
      }),
    ),
    total: PropTypes.shape({
      invoiceCount: PropTypes.number,
      netValue: PropTypes.string,
      documentsUrl: PropTypes.string,
    }),
  }),
  onFetchAnticipation: PropTypes.func,
  isAdmin: PropTypes.bool,
  vendor: PropTypes.object,
  operator: PropTypes.object,
};

ListingCard.defaultProps = {
  anticipation: {
    borderos: [],
    total: {
      invoiceCount: 0,
      netValue: 'R$ 0,00',
      documentsUrl: '#',
    },
  },
  onFetchAnticipation: () => null,
  isAdmin: false,
  vendor: {},
  operator: {},
};

const mapStateToProps = ({
  anticipationCheckout: { anticipation, isAdmin },
  vendorAdvances: { vendor, operator },
}) => ({
  anticipation,
  isAdmin,
  vendor,
  operator,
});

const mapDispatchToProps = {
  onFetchAnticipation: fetchAnticipation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingCard);
