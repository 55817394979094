const clear = () => {
  window.dataLayer.push({ ecommerce: null });
};

const emitCustomEvent = (event, data = {}) => {
  clear();
  window.dataLayer.push({
    event,
    ...data,
  });
};

const pushEcommerceEvent = (dataItems, event, metadata = {}) => {
  window.dataLayer.push({
    event,
    ecommerce: {
      ...metadata,
      items: dataItems,
    },
  });
};

// eslint-disable-next-line
const mapItems = ({ id, face, status, number, emitter_cnpj, cliente }) => ({
  // eslint-disable-next-line
  item_name: `${number} - ${cliente} - ${emitter_cnpj}`,
  item_id: `${id}`,
  price: `${face}`,
  item_brand: cliente,
  item_category: emitter_cnpj,
  item_category2: status,
  item_list_id: number,
  quantity: 1,
});

const addToCart = ({ items = [] }) => {
  clear();
  pushEcommerceEvent(items.map(mapItems), 'add_to_cart');
};

const removeFromCart = ({ items = [] }) => {
  clear();
  pushEcommerceEvent(items.map(mapItems), 'remove_from_cart');
};

const beginCheckout = ({ items = [] }) => {
  clear();
  pushEcommerceEvent(items.map(mapItems), 'begin_checkout');
};

const purchase = ({ items = [] }) => {
  clear();
  const total = items.reduce((totalValue, item) => totalValue + parseFloat(item.face), 0);
  pushEcommerceEvent(items.map(mapItems), 'purchase', {
    currency: 'BRL',
    value: `${total}`,
  });
};

export const Analytics = {
  emitCustomEvent,
};

export const Ecommerce = {
  addToCart,
  removeFromCart,
  beginCheckout,
  purchase,
};
