import 'core-js';
import ReactOnRails from 'react-on-rails';

import ChangePassword from '../src/views/change-password';
import Irpf from '../src/views/irpf';
import Login, { OperatorSelection } from '../src/views/login';
import WarningCard from '../src/views/investidor-not-approved';
import MultiSignView from '../src/views/multi-assinatura';
import WizardInvestidor from '../src/views/wizard-investidor';
import InvestorDashboard from '../src/views/investor-dashboard';
import InvestorStatement from '../src/views/investor-statement';
import MyAccount from '../src/views/my-account';
import MultiProposal from '../src/views/multi-proposal';
import InvoiceSimulation from '../src/views/fornecedor-duplicatas';
import VendorAdvances from '../src/views/fornecedor-vendas';
import ConfirmationHistory from '../src/views/confirmation-history';
import VendorInvoices from '../src/views/vendor-invoices';
import VendorBorderoShow from '../src/views/vendor-bordero-show';
import AnticipationCheckout from '../src/views/anticipation-checkout';
import AdminNegotiations from '../src/views/admin-negotiations';
import VendorReports from '../src/views/vendor-reports';
import AnticipationCancel from '../src/views/anticipation-cancel';
import AdminVendorAnticipations from '../src/views/admin-vendor-anticipations';
import AdminVendors from '../src/views/admin-vendors';
import InvestorAcceptTerms from '../src/views/investor-accept-terms';
import InvestorSpreadsheetRates from '../src/views/investor-spreadsheet-rates';
import InvestorRenegotiations from '../src/views/investor-renegotiations';
import BuyerVendors from '../src/views/buyer-vendors';
import VendorStatusOnBuyer from '../src/common-components/VendorStatusOnBuyer';
import VendorsGroups from '../src/views/vendors-groups';
import VendorInterest from '../src/views/vendor-interest';
import BuyerAnticipationLimits from '../src/views/buyer-anticipation-limits';
import HolydaysWarning from '../src/views/holydays_warning';
import OperatorMigrationWarning from '../src/views/operator-migration-warning';
import FunderTradesHeader from '../src/views/funder-trades-header';
import Schedules from '../src/views/schedules';
import BuyerLimits from '../src/views/buyer-limits';
import CreateVendorsGroup from '../src/views/create-vendors-group';
import VendorsGroupHistory from '../src/views/vendors-group-history';
import VendorsGroupDetails from '../src/views/vendors-group-details';
import BuyerSidebar from '../src/common-components/BuyerSidebar';
import FunderUploadedRatesPreview from '../src/views/funder-uploaded-rates-preview';
import Remittance from '../src/views/remittance';
import FunderVendorsGroups from '../src/views/funder-vendors-groups';
import OperatorTransferList from '../src/views/operator-transfer-list';
import ReplicateVendor from '../src/views/replicateVendor';
import VendorTransferList from '../src/views/vendor-transfer-list';
import FunderSpreadsheetRatesHistory from '../src/views/funder-spreadsheet-rates-history';
import FunderLogin from '../src/views/funder-authentication/components/FunderLogin';
import FunderRegister from '../src/views/funder-authentication/components/FunderRegister';
import FunderRecoverPassword from '../src/views/funder-authentication/components/FunderRecoverPassword';
import FunderResetPassword from '../src/views/funder-authentication/components/FunderResetPassword';
import FunderConfirmEmail from '../src/views/funder-authentication/components/FunderConfirmEmail';
import OperatorCompanySelection from '../src/views/operator-company-selection';
import FunderSpreadsheetRateUpload from '../src/views/funder-spreadsheet-rate-upload';
import FunderSidebar from '../src/common-components/FunderSidebar';
import FunderConciliation from '../src/views/funder-conciliation';
import BuyerOrganizationGroups from '../src/views/buyer-organization-groups';
import CreateBuyerOrganizationGroup from '../src/views/create-buyer-organization-group';
import BuyerOrganizationGroup from '../src/views/buyer-organization-group';
import EditBuyerOrganizationGroup from '../src/views/edit-buyer-organization-group';

ReactOnRails.register({
  ChangePassword,
  Irpf,
  Login,
  WarningCard,
  MultiSignView,
  WizardInvestidor,
  InvestorDashboard,
  InvestorStatement,
  MyAccount,
  OperatorSelection,
  MultiProposal,
  InvoiceSimulation,
  VendorAdvances,
  ConfirmationHistory,
  VendorInvoices,
  VendorBorderoShow,
  AnticipationCheckout,
  AdminNegotiations,
  VendorReports,
  AnticipationCancel,
  AdminVendorAnticipations,
  AdminVendors,
  InvestorAcceptTerms,
  InvestorSpreadsheetRates,
  InvestorRenegotiations,
  BuyerVendors,
  VendorsGroups,
  BuyerAnticipationLimits,
  HolydaysWarning,
  FunderTradesHeader,
  Schedules,
  BuyerLimits,
  CreateVendorsGroup,
  VendorsGroupHistory,
  VendorsGroupDetails,
  BuyerSidebar,
  OperatorMigrationWarning,
  FunderUploadedRatesPreview,
  Remittance,
  FunderVendorsGroups,
  OperatorTransferList,
  ReplicateVendor,
  VendorTransferList,
  VendorInterest,
  FunderSpreadsheetRatesHistory,
  FunderLogin,
  FunderRecoverPassword,
  FunderResetPassword,
  FunderRegister,
  FunderConfirmEmail,
  OperatorCompanySelection,
  FunderSpreadsheetRateUpload,
  VendorStatusOnBuyer,
  FunderSidebar,
  FunderConciliation,
  BuyerOrganizationGroups,
  CreateBuyerOrganizationGroup,
  BuyerOrganizationGroup,
  EditBuyerOrganizationGroup,
});
