import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinearProgress } from 'liber-components';
import { ButtonsRow, SecondaryButton, PrimaryButton } from '../AnticipationCheckout.styles';
import { Container, ProgressContainer, LoadingBunny, ProgressText } from './AssignmentTerm.styles';
import ListingCard from './ListingCard';
import ErrorState from './ErrorState';
import { dispatchDataLayerEvent } from '../../../../vendor/Utils';

export const AssignmentTerm = ({
  handleNext,
  advanceProgress,
  isDocumentReady,
  anticipationHasFailed,
  handleBack,
  isAdmin,
  vendor,
  operator,
}) => {
  useEffect(() => {
    if (!isDocumentReady) {
      if (process.env.NODE_ENV !== 'development') {
        window.onbeforeunload = function checkLeave() {
          return 'Sair desta página? Você irá cancelar o pedido de antecipação!';
        };
      }
    }
  }, []);

  const gaParams = useMemo(
    () => ({
      id: vendor.id,
      name: vendor.name,
      kind: isAdmin ? 'Admin' : 'Vendor',
      operatorId: operator.id,
      operatorName: operator.name,
      representanteLegal: operator.legal_representative,
    }),
    [isAdmin, vendor, operator],
  );

  const goToBackStage = useCallback(() => {
    dispatchDataLayerEvent('Vendor antecipation checkout: cancel antecipation', isAdmin, gaParams);
    handleBack();
  }, [gaParams]);

  const goToNextStage = useCallback(() => {
    dispatchDataLayerEvent('Vendor antecipation checkout: click continue', isAdmin, gaParams);
    handleNext();
  }, [gaParams]);

  return (
    <>
      {anticipationHasFailed && <ErrorState isAdmin={isAdmin} />}
      {isDocumentReady && !anticipationHasFailed ? (
        <Container>
          <ListingCard />
          <ButtonsRow>
            <SecondaryButton onClick={goToBackStage}>VOLTAR</SecondaryButton>
            <PrimaryButton onClick={goToNextStage}>CONTINUAR</PrimaryButton>
          </ButtonsRow>
        </Container>
      ) : (
        !anticipationHasFailed && (
          <Container>
            <LoadingBunny loop />
            <ProgressContainer>
              <LinearProgress height={16} determinate value={advanceProgress} />
            </ProgressContainer>
            <ProgressText>{`${advanceProgress.toFixed(0)}%`}</ProgressText>
          </Container>
        )
      )}
    </>
  );
};

AssignmentTerm.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  advanceProgress: PropTypes.number,
  isDocumentReady: PropTypes.bool,
  anticipationHasFailed: PropTypes.bool,
  isAdmin: PropTypes.bool,
  vendor: PropTypes.object,
  operator: PropTypes.object,
};

AssignmentTerm.defaultProps = {
  handleNext: () => null,
  handleBack: () => null,
  advanceProgress: 50,
  isDocumentReady: false,
  anticipationHasFailed: false,
  isAdmin: false,
  vendor: {},
  operator: {},
};

const mapStateToProps = ({
  anticipationCheckout: { advanceProgress, isDocumentReady, anticipationHasFailed, isAdmin },
  vendorAdvances: { vendor, operator },
}) => ({
  advanceProgress,
  isDocumentReady,
  anticipationHasFailed,
  isAdmin,
  vendor,
  operator,
});

export default connect(mapStateToProps)(AssignmentTerm);
