import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EmptyImage from '../../../../../../assets/images/empty.svg';
import { ImageContainer, Image, ButtonsRow, PrimaryButton } from '../AnticipationCheckout.styles';
import { SecondaryButton } from './AssignmentTerm.styles';
import { generateAnticipation } from '../../actions/AnticipationCheckoutActions';
import { dispatchDataLayerEvent } from '../../../../vendor/Utils';

export const ErrorState = ({ onRetry, isAdmin }) => {
  const handleSuspendClick = () => {
    dispatchDataLayerEvent('checkoutSuspender', isAdmin);
  };

  const handleRepeatClick = () => {
    dispatchDataLayerEvent('checkoutRepetir', isAdmin);

    onRetry();
  };

  return (
    <>
      <ImageContainer>
        <Image src={EmptyImage} />
      </ImageContainer>
      <ButtonsRow>
        <SecondaryButton href="/fornecedor/duplicatas" onClick={handleSuspendClick}>
          SUSPENDER
        </SecondaryButton>
        <PrimaryButton onClick={handleRepeatClick}>REPETIR</PrimaryButton>
      </ButtonsRow>
    </>
  );
};

ErrorState.propTypes = {
  onRetry: PropTypes.func,
  isAdmin: PropTypes.func,
};

ErrorState.defaultProps = {
  onRetry: () => null,
  isAdmin: false,
};

const mapDispatchToProps = {
  onRetry: generateAnticipation,
};

export default connect(null, mapDispatchToProps)(ErrorState);
