import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import DateFieldFilter from '../../../Filters/DateFieldFilter/DateFieldFilter';
import TextFieldFilter from '../../../Filters/TextFieldFilter/TextFieldFilter';
import OptionsFilter from '../../../Filters/OptionsFilter/OptionsFilter';
import { SubTitle } from '../VendorAdvances.styles';
import PendingAdvanceCard from '../PendingAdvanceCard';
import { statusReference } from '../PendingAdvanceCard/constants';

import {
  AdvancesContainer,
  Section,
  FiltersContainer,
  FiltersLabel,
  SelectField,
  FiltersWrapper,
  EmptyState,
} from './PendingAdvances.styles';
import { dispatchDataLayerEvent } from '../../../../vendor/Utils';

export const STATUS_SORT = 'status';
export const REQUESTED_AT_SORT = 'requestedAt';

export const PendingAdvances = ({
  pendingAdvances,
  onDetailsClick,
  isAdmin,
  politicaOfertaTitulos,
  timeCut,
  vendor,
  operator,
}) => {
  const [sortBy, setSortBy] = useState(STATUS_SORT);
  const [number, setNumber] = useState();
  const [status, setStatus] = useState();
  const [signed, setSigned] = useState();
  const [requestedAt, setRequestedAt] = useState();

  const advances = useMemo(() => {
    const formattedAdvances = pendingAdvances.map(
      ({ requestedAt: advanceRequestedAt, ...rest }) => ({
        ...rest,
        requestedAt: advanceRequestedAt,
        requestedAtDate: moment(advanceRequestedAt, 'DD/MM/YYYY[ às ]hh:mm[h]').toDate(),
      }),
    );

    const filteredAdvances = [...formattedAdvances].filter(
      ({ id: advanceNumber, mappedStatus, userHasSigned, requestedAtDate }) => {
        if (number && !advanceNumber.toString().includes(number)) return false;
        if (status && !status.includes(mappedStatus)) return false;
        if (signed && !signed.includes(userHasSigned)) return false;

        if (requestedAt?.start && requestedAt.start.startOf('day').toDate() > requestedAtDate)
          return false;
        if (requestedAt?.end && requestedAt.end.endOf('day').toDate() < requestedAtDate)
          return false;

        return true;
      },
    );

    const sortedAdvances = [...filteredAdvances].sort(
      (
        { requestedAtDate: firstDate, mappedStatus: firstMappedStatus },
        { requestedAtDate: secondDate, mappedStatus: secondMappedStatus },
      ) => {
        if (sortBy === STATUS_SORT) {
          return firstMappedStatus?.localeCompare(secondMappedStatus) ? 1 : -1;
        }
        return firstDate > secondDate ? 1 : -1;
      },
    );

    return sortedAdvances;
  }, [pendingAdvances, sortBy, number, status, signed, requestedAt]);

  const handleDispatchFilterEvent = useCallback(() => {
    dispatchDataLayerEvent('Vendor antecipation: apply filter', isAdmin, {
      id: vendor.id,
      name: vendor.name,
      kind: isAdmin ? 'Admin' : 'Vendor',
      operatorId: operator.id,
      operatorName: operator.name,
      representanteLegal: operator.legal_representative,
    });
  }, [operator]);

  return (
    <>
      {pendingAdvances.length > 0 && (
        <Section>
          <SubTitle>Antecipações em andamento ({advances.length})</SubTitle>
          <FiltersContainer>
            <FiltersWrapper $hideOnMobile>
              <FiltersLabel>Filtros</FiltersLabel>
              <TextFieldFilter
                data-testid="TextFieldFilter"
                label="Antecipação"
                selectedItems={number}
                onClear={() => {
                  handleDispatchFilterEvent();
                  setNumber(undefined);
                }}
                onFilter={input => setNumber(input)}
              />
              <OptionsFilter
                filterValues={status}
                onFilter={input => {
                  handleDispatchFilterEvent();
                  setStatus(input);
                }}
                onClear={() => setStatus(undefined)}
                options={Object.entries(statusReference).map(([key, value]) => ({
                  option: value,
                  value: key,
                }))}
                label="Status"
              />
              <OptionsFilter
                filterValues={signed}
                onFilter={input => {
                  handleDispatchFilterEvent();
                  setSigned(input);
                }}
                onClear={() => setSigned(undefined)}
                options={[
                  { option: 'Assinados por mim', value: true },
                  { option: 'Pendentes da minha assinatura', value: false },
                ]}
                label="Minha assinatura"
              />
              <DateFieldFilter
                label="Data do pedido"
                onFilter={input => {
                  handleDispatchFilterEvent();
                  setRequestedAt(input);
                }}
                onClear={() => setRequestedAt(undefined)}
                selectedItems={[requestedAt?.start, requestedAt?.end]}
              />
            </FiltersWrapper>
            <FiltersWrapper>
              <FiltersLabel>Ordenar por:</FiltersLabel>
              <SelectField
                data-testid="SortSelect"
                value={sortBy}
                onChange={value => setSortBy(value)}
              >
                <option value={STATUS_SORT}>Status</option>
                <option value={REQUESTED_AT_SORT}>Data do pedido</option>
              </SelectField>
            </FiltersWrapper>
          </FiltersContainer>
          {advances.length > 0 ? (
            <AdvancesContainer>
              {advances.map((advance, index) => (
                <PendingAdvanceCard
                  testId={`item-${index}-${advance.id}`}
                  key={advance.id}
                  advance={advance}
                  onDetailsClick={onDetailsClick}
                  isAdmin={isAdmin}
                  politicaOfertaTitulos={politicaOfertaTitulos}
                  timeCut={timeCut}
                  vendor={vendor}
                  operator={operator}
                />
              ))}
            </AdvancesContainer>
          ) : (
            <EmptyState>Nenhum item para ser exibido.</EmptyState>
          )}
        </Section>
      )}
    </>
  );
};

PendingAdvances.propTypes = {
  pendingAdvances: PropTypes.arrayOf(PropTypes.object),
  onDetailsClick: PropTypes.func,
  isAdmin: PropTypes.bool,
  politicaOfertaTitulos: PropTypes.string,
  timeCut: PropTypes.string,
  vendor: PropTypes.object,
  operator: PropTypes.object,
};

PendingAdvances.defaultProps = {
  pendingAdvances: [],
  onDetailsClick: () => null,
  isAdmin: false,
  politicaOfertaTitulos: null,
  timeCut: null,
  vendor: {},
  operator: {},
};

const mapStateToProps = ({
  vendorAdvances: { pendingAdvances, isAdmin, timeCut, vendor, operator },
}) => ({
  pendingAdvances,
  isAdmin,
  timeCut,
  vendor,
  operator,
});

export default connect(mapStateToProps)(PendingAdvances);
